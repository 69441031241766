<template>
  <div class="phone-confirmation">
    <div class="limit">
      <router-link to="/account/login">
        <img src="../../../../assets/images/ommed_logo.svg" class="logo" />
      </router-link>
      <div class="container-form">
        <FormCode @success="codeSuccess" v-if="!code" />
        <FormPassword v-else />
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import FormCode from "./FormCode"
import FormPassword from "./FormPassword"
import { PhoneConfirmation } from "./actions"

export default {
  components: {
    FormCode,
    FormPassword,
  },
  setup() {
    const router = useRouter();
    const code = ref(false);
    const key = ref("");

    const codeSuccess = (keyCode) => {
      localStorage.setItem("keyCode", keyCode);
      key.value = keyCode;
      code.value = true;
    }

    onMounted(() => {
      const keyCode = localStorage.getItem("keyCode");

      console.log(keyCode);

      if(keyCode){
        code.value = true;
        key.value = keyCode;
      }

    })

    return {
      code,
      key,
      codeSuccess,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.phone-confirmation {
  display: flex;
  flex-direction: column;
  background: #F2F2F2;
  max-width: 100%;

  .limit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: max-content;
    padding: 38px;
    min-height: 100vh;
    a{
      width: max-content;
      padding: 0;
    }
    .logo{
      padding: 0;
    }
  }
}


@media (max-width: 1399px) {
  .register {
    .limit {
      width: 100%;
    }
  }
}

@media (min-width: 1420px) {
  .register {
    .limit {
      width: 1400px;
    }
  }
}
</style>
  