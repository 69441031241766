import patientAccountService from "../../../core/services/patientAcountService";
import { 
    VerifyPasswordRecoveryCodeRequest,
    SendPasswordRecoveryCodeToEmailRequest,
    UpdatePasswordRequest,
    EmptyRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const VerifyPasswordRecoveryCode = async (obj) => {
    try {
        const req = new VerifyPasswordRecoveryCodeRequest();
        req.setUser(obj.user);
        req.setCode(obj.code);

        const res = await patientAccountService.verifyPasswordRecoveryCode(req);

        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SendPasswordRecoveryCodeToEmail = async (email) => {
    try {
        const req = new SendPasswordRecoveryCodeToEmailRequest();
        req.setEmail(email);

        const res = await patientAccountService.sendPasswordRecoveryCodeToEmail(req);

        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const UpdatePassword = async (obj) => {
    try {
        console.log(obj);
        const req = new UpdatePasswordRequest();
        req.setPassword(obj.password);
        req.setConfirm(obj.confirm);
        req.setPasswordrecoverytoken(obj.recoverytoken);

        const res = await patientAccountService.updatePassword(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const VerifyToken = async () => {
    try {
        const req = new EmptyRequest();

        const res = await patientAccountService.verifyToken(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export {
    VerifyPasswordRecoveryCode,
    SendPasswordRecoveryCodeToEmail,
    UpdatePassword,
    VerifyToken,
}