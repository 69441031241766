<template>
    <div class="form">
        <div class="header">
            <h1>Atualizar Senha</h1>
        </div>

        <div class="limit">
            <p class="text-sendsms mt-16">Escolha uma nova senha com o mínimo de 6 dígitos e contendo pelo menos um número e uma letra.</p>
            <PasswordForm 
                :error="error.password"
                @submit="submit" 
                v-model="password" 
            />
            <ConfirmForm 
                :error="error.confirm"
                @submit="submit" 
                v-model="confirm" 
            />
            <ForcePassword :password="password" />

            <div class="flex w-full justify-content-center" style="margin-top: 8px;">
                <Button :disabled="!buttonActive.status || loading" class="btn-submit" @click="submit">
                    Confirmar
                    <i class="pi pi-spin pi-spinner" v-if="loading" />
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
// import { useRouter } from "vue-router";
import PasswordForm from "./components/PasswordForm"
import ConfirmForm from "./components/ConfirmForm"
import ForcePassword from "../Register/components/ForcePassword"
import { 
    UpdatePassword,
} from "./actions";

// let router = useRouter();
const loading = ref(false);
const password = ref("");
const confirm = ref("");
const error = ref({ 
    password: [], 
    confirm: [] 
});

const buttonActive = computed(() => {
    if(password.value.length && confirm.value.length){
        return { status: true, class: '' }
    }

    return { status: false, class: '' }
})

const submit = async () => {
    loading.value = true;
    const token = localStorage.getItem("keyCode");
    const obj = { password: password.value, confirm: confirm.value, recoverytoken: token }
    const res = await UpdatePassword(obj);


    if(res.success){
        // router.push("/account/login")
        window.location.href = "/account/login";
    } else {
        const data = JSON?.parse(res.data);

        if(data?.password){
            error.value.password = data.password;
        } else{
            error.value.password = [];
        }

        if(data?.confirm){
            error.value.confirm = data.confirm;
        } else{
            error.value.confirm = [];
        }
    }

    console.log(res);
    loading.value = false;
};
</script>


<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    height: max-content;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;

        h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }

    .limit {
        width: 100% !important;
        padding: 0 32px;

        .text-sendsms {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            max-width: 40ch;
            margin: 0 auto;
        }

        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
        }

        .ask-notnumber {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #2F80ED;
            text-align: center;
            margin: 0 auto;
            /* transform: translateY(-2px); */
            cursor: pointer;
        }
        .color-primary{
            color: #FF6A33 !important;
            letter-spacing: .3px;
        }

        .sms-container {
            width: max-content;
            margin: 0 auto;
            margin-top: 6px;
            text-align: center;
            .input-code{
                width: max-content;
                font-size: 18px;
                padding: 0;
                height: 55px;
                letter-spacing: 5px;
                font-weight: 600;
            }
        }

        .sms-codetext {
            margin-top: 16px;

            .time-code {
                font-size: 14px;
                line-height: 22px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                color: #828282;
            }
        }
        .btn-submit{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            min-width: 230px;
            height: 42px !important;
        }
    }
}

@media (max-width: 580px) {
    .form {
        width: 100%;
    }
}

::v-deep(.p-button) {
    margin-top: 10px;
    padding: 20px 80px;
    width: 100%;
}

::v-deep(.p-inputmask) {
    text-align: center;
    letter-spacing: 3px;
    font-size: 20px;
}
</style>