<template>
    <div class="password-form mt-16">
        <span class="p-input-icon-right">
            <i 
                class="pi pi-eye" 
                v-tooltip.top="{ value: viewPassword ? 'Ocultar senha' : 'Mostrar senha' }"
                @click="viewPassword = !viewPassword"
            />
            <InputText
                :value="modelValue"
                :class="conditionError.class"
                :type="!viewPassword ? 'password' : 'text'"
                placeholder="Senha"
                :maxlength="100"
                @input="(e) => $emit('update:modelValue', e.target.value)"
                @keyup.enter="$emit('submit')"
            />
        </span>
        <span class="p-invalid" v-if="error.length">{{ error[0] }}</span>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export default {
    props: ["modelValue", "error"],
    emits: ["update:modelValue", "submit"],
    setup(props, { emit }) {
        let router = useRouter();
        const viewPassword = ref(false);
        

        const conditionError = computed(() => {
            if( props.error.length ){
                return { status: true, class: 'p-invalid' }
            }
            return { status: false, class: '' }
        })

        return{
            conditionError,
            viewPassword,
        }
    },
};
</script>


<style lang="scss" scoped>
.password-form {
    width: 100%;
    ul{
        margin: 0;
        padding: 0;
    }
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
    .forgot{
        margin-top: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #2F80ED;
        span{
            cursor: pointer;
            &:hover{
                text-shadow: #2F80ED 0px 0px 0.1rem;
            }
        }
    }
}

::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    border-radius: 8px;
    &::placeholder{
        color: #BDBDBD;       
    }
}

::v-deep(.p-input-icon-right) {
    width: 100%;
    .pi{
        margin-right: 10px;
        cursor: póinter !important;
    }
}

::v-deep(.pi-eye) {
    cursor: pointer;
}
</style>