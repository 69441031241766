<template>
    <div class="forcepassword-form">
        <k-progress
            class="progress"
            :percent="strength.valor"
            :cut-width="2"
            :show-text="false"
            :color="strength.cor"
            :line-height="8"
        />
        <span :style="{color: strength.cor}">
            {{strength.text}}
        </span>      
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";

const special = /\W|_/;
const hasUpper = str => /[A-Z]/.test(str);
const hasLower = str => /[a-z]/.test(str);

const forceHigh = pass => {
    if( special.test(pass) || (hasUpper(pass) && hasLower(pass)) ){
        return true;
    }

    return false;
}
const forceStrong = pass => {
    if( special.test(pass) && hasUpper(pass) && hasLower(pass) ){
        return true;
    }

    return false;
}

export default {
    props: ["password"],
    setup(props, { emit }) {
        const text = ref("");
        const strength = computed(() => {
            let value = 0;
            let color = "";
            let text = "Força da senha";

            if ( forceStrong(props.password) ) {
                value = 100;
                color = "#27AE60";
                text = "Senha forte."
            } else if ( forceHigh(props.password) ) {
                value = 66;
                color = "#F2C94C";
                text = "Senha razoável.";
            } else if ( props.password.length ) {
                value = 33;
                color = "#EB5757";
                text = "Senha fraca.";
            }

            return { valor: value, cor: color, text: text }
        })

        return{
            text,
            strength
        }
    },
};


</script>


<style lang="scss" scoped>
.forcepassword-form {
    width: 100%;
    margin-top: 12px;
    span{
        color: #828282;
        font-size: 12px;
        margin-left: 16px;
        font-weight: 400;
        line-height: 18px;
        /* letter-spacing: 1px; */
        margin-bottom: 28px;
        margin-top: 8px;
    }
}


::v-deep(.k-progress) {
    width: 100%;
    height: 16px;
}
::v-deep(.k-progress-outer) {
    max-width: 100%;
    padding-right: 0px;
}
::v-deep(.k-progress-outer-bg) {
    /* border: 1px solid red; */
    height: 16px !important;
}
::v-deep(.k-progress-outer-bg-border) {
    border: 1px solid #f2f2f2;
    height: 16px !important;
    background: white !important;
    border-radius: 4px !important;
}
::v-deep(.k-progress-outer-line) {
    height: 14px !important;
    margin-top: -15px !important;
    margin-right: 1px;
    margin-left: 1px;
    border-radius: 4px;
}
</style>